import { FC, ReactNode, useEffect } from "react"

interface Props {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  verticalCenter?: boolean
}

const Popup: FC<Props> = ({
  isOpen,
  onClose,
  children,
  verticalCenter = true,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div className="fixed overflow-auto inset-0 z-9999 bg-black bg-opacity-50 backdrop-blur-sm">
      <div
        className={`absolute left-1/2 -translate-x-1/2
        text-center ${verticalCenter ? `top-1/2 -translate-y-1/2` : `pb-8 pt-8`}`}
      >
        <div className="relative pb-12 bg-white rounded-xl shadow-lg px-6 pt-16">
          <button
            onClick={onClose}
            className="absolute text-3xl font-thin top-6 right-6 text-gray-cadet hover:text-black"
          >
            ✕
          </button>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Popup
