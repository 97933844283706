import {
  ChangeEvent,
  SetStateAction,
  useState,
  useEffect,
  Dispatch,
} from "react"
import { string } from "yup"
import { useGenerateWellnessCodeMutation } from "app/services"
import { WellnessCodeType } from "constants/authConstants"
import { PHONE_RULES } from "constants/validationConstants"

interface Props {
  setUserId: Dispatch<SetStateAction<string | undefined>>
  setContact: Dispatch<SetStateAction<string | undefined>>
}

const useSendWellnessCode = ({ setUserId, setContact }: Props) => {
  const [value, setValue] = useState("")
  const [error, setError] = useState<string>()
  const [sendCode, { isLoading, data }] = useGenerateWellnessCodeMutation()

  useEffect(() => {
    if (data && data?.id) {
      setUserId(String(data.id))
      setContact(value)
    }
  }, [data])

  const handleSumbit = () => {
    let type = null
    if (value.match(/@/)) {
      if (!string().email().isValidSync(value)) {
        setError("This email address isn't valid. Please check and try again.")
        return
      }
      type = WellnessCodeType.EMAIL
    } else {
      if (!PHONE_RULES.isValidSync(value)) {
        setError("This phone number isn't valid. Please check and try again.")
        return
      }
      type = WellnessCodeType.PHONE
    }

    setError(undefined)
    sendCode({ type, value })
  }

  const handleInputChange = (e: ChangeEvent<any>) => {
    setValue(e.target.value)
  }

  return {
    handleInputChange,
    handleSumbit,
    isButtonDisabled: !Boolean(value) || isLoading,
    error,
  }
}

export default useSendWellnessCode
