import Title from "components/text/Title"
import TextButton from "components/buttons/TextButton"
import TextField from "components/fields/TextField"
import { Dispatch, FC, SetStateAction } from "react"
import { User } from "types/entities/userTypes"
import { formatDate } from "utils/dateTimeUtils"

interface Props {
  setEdit: Dispatch<SetStateAction<boolean>>
  account: User
}

const AccountDetailsView: FC<Props> = ({ setEdit, account }) => {
  return (
    <>
      <div className="flex justify-between">
        <Title>Details</Title>
        <div>
          <TextButton icon={"edit"} onClick={() => setEdit(true)}>
            Edit
          </TextButton>
        </div>
      </div>
      <TextField
        name="Name"
        value={(account.name_first ?? "") + " " + (account.name_last ?? "")}
      />
      <TextField name="Date of birth" value={formatDate(account.birth_date)} />
    </>
  )
}

export default AccountDetailsView
