import { FC, InputHTMLAttributes } from "react"
import { BaseFieldProps } from "types/core/formTypes"

interface Props extends BaseFieldProps, InputHTMLAttributes<HTMLInputElement> {}

const CheckboxInput: FC<Props> = ({ label, error, ...inputProps }) => {
  return (
    <div className="relative cursor-pointer">
      <label className="flex items-center select-none">
        <input
          type="checkbox"
          className="block appearance-none h-7 w-7 cursor-pointer bg-center bg-no-repeat bg-unchecked checked:bg-checked"
          {...inputProps}
          checked={Boolean(inputProps.value)}
        />
        {label && (
          <span className="block pl-2 font-semibold cursor-pointer">
            {label}
          </span>
        )}
      </label>
    </div>
  )
}

export default CheckboxInput
