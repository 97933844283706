import { FC, ReactNode } from "react"
import BaseHeader from "layouts/BaseHeader"
import BaseFooter from "layouts/BaseFooter"
import AutoBreadcrumbs from "features/navigation/AutoBreadcrumbs"
import Title from "components/text/Title"

interface Props {
  children: ReactNode
  title?: string
  maxWidth?: string
  hasBreadcrumbs?: boolean
}

const BaseLayout: FC<Props> = ({
  children,
  title,
  maxWidth = "max-w-5xl",
  hasBreadcrumbs = true,
}) => {
  return (
    <div className="flex flex-col min-h-screen">
      <BaseHeader />
      <div className="flex-grow container mx-auto px-4 py-12">
        <div className={`${maxWidth} md:mx-auto`}>
          {title && <Title size="xl">{title}</Title>}
          {hasBreadcrumbs && <AutoBreadcrumbs />}
          {children}
        </div>
      </div>
      <BaseFooter />
    </div>
  )
}

export default BaseLayout
