import {
  useGenerateWellnessCodeMutation,
  useUpdateAccountMutation,
} from "app/services"
import { useEffect, useState } from "react"
import { WellnessCodeRequest } from "types/authTypes"

interface Props {
  name: "phone" | "email"
}

const useUpdateByWellnessCode = ({ name }: Props) => {
  const [sendWellnessCode] = useGenerateWellnessCodeMutation()
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [contact, setContact] = useState<string>()
  const [updateAccount, { isLoading: isUpdating, isError, isSuccess }] =
    useUpdateAccountMutation()

  const update = (key: string) => {
    updateAccount({
      [name]: contact,
      wellness_key: key,
    })
  }

  const closePopup = () => setPopupOpen(false)

  const sendCode = ({ type, value }: WellnessCodeRequest) => {
    sendWellnessCode({ type, value })
    setContact(value)
    setPopupOpen(true)
  }

  useEffect(() => {
    if (isSuccess) {
      closePopup()
    }
  }, [isSuccess])

  return {
    isPopupOpen,
    closePopup,
    sendCode,
    update,
    contact,
    isLoading: isUpdating,
    updateError: isError ? "Invalid code" : undefined,
  }
}

export default useUpdateByWellnessCode
