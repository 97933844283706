import { ClipboardEvent, useState } from "react"

interface Props {
  onSubmit: (value: string) => void
}

const useWellnessCodeForm = ({ onSubmit }: Props) => {
  const [values, setValues] = useState(["", "", "", "", "", ""])
  const value = values.join("")

  const handlePaste = (e: ClipboardEvent<any>) => {
    const pasted = e.clipboardData.getData("text/plain")
    setValues(pasted.split("").slice(0, values.length))
  }

  const handleInputChange = (index: number, val: string) => {
    setValues((prev) => {
      const updated = [...prev]
      updated[index] = val
      return updated
    })
  }

  const handleSubmit = () => {
    onSubmit(value)
  }

  return {
    handleInputChange,
    handlePaste,
    isSubmitDisabled: value.length < 6,
    values,
    handleSubmit,
  }
}

export default useWellnessCodeForm
