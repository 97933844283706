import DatePicker, { DatePickerProps } from "react-date-picker"
import { BaseFieldProps } from "types/core/formTypes"
import { FC } from "react"
import InputLabel from "components/inputs/InputLabel"
import InputError from "components/inputs/InputError"
import "react-date-picker/dist/DatePicker.css"
import "react-calendar/dist/Calendar.css"
import "components/inputs/DateInput.css"

interface Props extends BaseFieldProps, DatePickerProps {}

const DateInput: FC<Props> = ({ label, error, ...inputProps }) => {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <DatePicker
        format="MM/dd/yyyy"
        calendarIcon={null}
        clearIcon={null}
        {...inputProps}
      />
      <InputError>{error}</InputError>
    </div>
  )
}

export default DateInput
