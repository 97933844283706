import { FC, useState } from "react"
import { twMerge } from "tailwind-merge"

interface Props {
  items: string[]
  onChange: (item: string) => void
  className?: string
}

const Tabs: FC<Props> = ({ items, onChange, className }) => {
  const [active, setActive] = useState(0)

  const handleChange = (index: number) => {
    setActive(index)
    onChange(items[index])
  }

  return (
    <div
      className={`flex justify-between rounded-lg p-1 bg-white-ghost border border-gray-bright text-center 
        font-semibold text-jacarta ${className}`}
    >
      {items.map((item, i) => (
        <div
          key={i}
          className={twMerge(
            "cursor-pointer py-3 flex-grow rounded-lg",
            active === i && "bg-white shadow",
          )}
          onClick={() => handleChange(i)}
        >
          {item}
        </div>
      ))}
    </div>
  )
}

export default Tabs
