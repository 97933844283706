import Breadcrumbs from "features/navigation/Breadcrumbs"
import { useMatches } from "react-router-dom"
import { AppUIMatch } from "types/core/reactRouterTypes"
import { Breadcrumb } from "types/navigationTypes"

const AutoBreadcrumbs = () => {
  const matches = useMatches() as AppUIMatch[]
  const crumbs: Breadcrumb[] = []

  matches.map((item) => {
    if (item.handle.crumb) {
      crumbs.push({
        name: item.handle.crumb,
        link: item.pathname,
      })
    }
  })

  return <Breadcrumbs crumbs={crumbs} />
}

export default AutoBreadcrumbs
