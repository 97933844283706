import { ButtonHTMLAttributes, FC, ReactNode } from "react"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  className?: string
}

const Box: FC<Props> = ({ children, className }) => {
  return (
    <div className={`p-8 border rounded-xl bg-gray-50 ${className}`}>
      {children}
    </div>
  )
}

export default Box
