import { ReactComponent as LogoSvg } from "assets/images/logo.svg"
import { ReactComponent as LogoutSvg } from "assets/images/logout.svg"
import { ReactComponent as ProfileSvg } from "assets/images/profile.svg"
import SignInPopup from "features/auth/SignInPopup"
import useLogut from "hooks/auth/useLogout"
import useAuth from "hooks/auth/useAuth"
import { Link, Navigate, useMatch } from "react-router-dom"
import useSignInPopup from "features/auth/useSignInPopup"

const BaseHeader = () => {
  const user = useAuth()
  const { showSignIn } = useSignInPopup()
  const logout = useLogut()

  // TODO move to routers
  const match = useMatch("/account/*")
  if (match && !user) {
    return <Navigate to={"/"} />
  }

  return (
    <div className="shadow">
      <header className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link to="/">
            <LogoSvg />
          </Link>
          <div className="cursor-pointer">
            {user ? (
              <div className="grid grid-cols-2 gap-x-4">
                <div>
                  <Link to="/account">
                    <ProfileSvg />
                  </Link>
                </div>
                <div>
                  <LogoutSvg onClick={() => logout()} />
                </div>
              </div>
            ) : (
              <div>
                <ProfileSvg onClick={() => showSignIn()} />
              </div>
            )}
          </div>
        </div>
      </header>
      <SignInPopup />
    </div>
  )
}

export default BaseHeader
