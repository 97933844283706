import BaseHeader from "../layouts/BaseHeader"
import BaseFooter from "../layouts/BaseFooter"
import Title from "../components/text/Title"
import Text from "../components/text/Text"
import Button from "../components/buttons/Button"
import Image from "../components/images/Image"
import SemaglutideDefault from "../assets/images/semaglutide-default.png"
import { ReactComponent as CreditCardRefreshIcon } from "assets/images/credit-card-refresh.svg"
import InfoBox from "../components/ui/InfoBox"
import { useGetSubscriptionQuery } from "../app/services/subscriptionApi"

const Subscription = () => {
  const subscriptionId = 999 // TODO: change
  const { data } = useGetSubscriptionQuery(subscriptionId)

  const getBillingText = (billingPeriod: string) => {
    switch (billingPeriod) {
      case "custom":
        return `Billed every ${data?.billingcustom} days`
      case "monthly":
        return "Billed every month"
      case "quarterly":
        return "Billed every quarter"
      case "6-month":
        return "Billed every 6 months"
      case "yearly":
        return "Billed every year"
    }
  }

  return (
    <>
      <BaseHeader />
      <main className="flex flex-col items-center justify-center py-10 px-4">
        <div className="w-full max-w-6xl flex flex-col md:flex-row items-center md:items-start px-4 md:px-0 mx-auto">
          <Image
            src={SemaglutideDefault}
            alt="Semaglutide Weight Loss Program"
          />

          <div className="w-full md:w-1/2 lg:w-2/3 md:ml-8 mt-6 md:mt-0">
            <Title>{data?.title}</Title>
            <InfoBox
              text="This product is available through a subscription"
              SvgIcon={CreditCardRefreshIcon}
              className="my-3"
            />
            <div className="flex items-center space-x-3 my-3">
              <Title size="s">${data?.billing_cost}.00</Title>
              <Text color="grey" size="m">
                {getBillingText(data?.billing_period || "monthly")}
              </Text>
            </div>

            <Button size="s">Start your subscription</Button>
          </div>
        </div>

        <div className="w-full max-w-6xl flex flex-col md:flex-row items-center md:items-start px-4 md:px-0 mx-auto my-8">
          <Text color="grey" size="l">
            {data?.description}
          </Text>
        </div>
      </main>

      <BaseFooter />
    </>
  )
}

export default Subscription
