import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import React from "react"
import {
  AccountAddresses,
  AccountDashboard,
  AccountInformation,
  AccountOrders,
  AccountPaymentMethods,
  AccountSubscriptions,
  Subscription,
} from "pages"

export const router = createBrowserRouter([
  {
    path: "/account",
    handle: {
      crumb: "My Account",
    },
    children: [
      {
        element: <AccountDashboard />,
        index: true,
      },
      {
        path: "info",
        element: <AccountInformation />,
        handle: {
          crumb: "Account Information",
        },
      },
      {
        path: "subscriptions",
        element: <AccountSubscriptions />,
        handle: {
          crumb: "Subscriptions",
        },
      },
      {
        path: "orders",
        element: <AccountOrders />,
        handle: {
          crumb: "Purchase History",
        },
      },
      {
        path: "payment-methods",
        element: <AccountPaymentMethods />,
        handle: {
          crumb: "Payment Methods",
        },
      },
      {
        path: "addresses",
        element: <AccountAddresses />,
        handle: {
          crumb: "Addresses",
        },
      },
    ],
  },
  {
    path: "/subscription",
    element: <Subscription />,
  },
  {
    path: "/",
    element: <Navigate to="/subscription" replace />,
  },
])

const AppRoutes = () => {
  return <RouterProvider router={router} />
}

export default AppRoutes
