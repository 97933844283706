import { EndpointBuilder } from "@reduxjs/toolkit/query"
import { entityId } from "types/core/basicEntityTypes"

export const buildRest = <T extends entityId>(
  build: EndpointBuilder<any, any, any>,
  endpoint: string,
) => ({
  one: build.query<T, number>({
    query: (id) => `${endpoint}/${id}`,
  }),

  list: build.query<T, void>({
    query: () => endpoint,
  }),

  create: build.mutation<T, Partial<T>>({
    query: (body) => ({
      url: endpoint,
      method: "POST",
      body: JSON.stringify(body),
    }),
  }),

  update: build.mutation<T, Partial<T>>({
    query: ({ id, ...body }) => {
      return {
        url: `${endpoint}/${id}`,
        method: "PATCH",
        headers: {
          "content-type": "application/merge-patch+json",
        },
        body: JSON.stringify(body),
      }
    },
  }),

  delete: build.mutation<T, number>({
    query: (id) => ({
      url: `${endpoint}/${id}`,
      method: "DELETE",
    }),
  }),
})
