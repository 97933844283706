import { forwardRef, InputHTMLAttributes } from "react"
import { BaseFieldProps } from "types/core/formTypes"
import InputLabel from "components/inputs/InputLabel"
import InputError from "components/inputs/InputError"

interface Props extends BaseFieldProps, InputHTMLAttributes<HTMLInputElement> {
  inputSize?: "s" | "m" | "l"
}

const sizeClasses: Record<string, string> = {
  s: "text-sm",
  m: "text-base",
  l: "text-3xl",
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ label, error, inputSize = "m", className, ...inputProps }, ref) => {
    const errorClass = error && "border-red"
    const sizeClass = sizeClasses[inputSize]

    return (
      <div>
        <InputLabel>{label}</InputLabel>
        <input
          className={`
          block w-full rounded-md p-4 leading-none border border-silver focus:outline-none focus:border-jacarta
          placeholder-silver-quick ${errorClass} ${sizeClass} ${className}
        `}
          type="text"
          {...inputProps}
          ref={ref}
        />
        <InputError>{error}</InputError>
      </div>
    )
  },
)

export default TextInput
