import Table from "components/tables/Table"

const InactiveSubscriptions = () => {
  const head = ["Name", "Date canceled", "Price", ""]

  return (
    <Table
      title="Inactive subscriptions"
      head={head}
      data={[]}
      emptyText="You have no inactive subscriptions."
    />
  )
}

export default InactiveSubscriptions
