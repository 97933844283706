import TextInput from "components/inputs/TextInput"
import { FC } from "react"
import DateInput from "components/inputs/DateInput"
import CheckboxInput from "components/inputs/CheckboxInput"
import SelectInput from "components/inputs/SelectInput"

export enum FormFieldType {
  TEXT,
  SELECT,
  DATE,
  CHECKBOX,
}

type formFieldComponent = {
  [key in FormFieldType]: FC<any>
}

export const FORM_FIELD_COMPONENTS: formFieldComponent = {
  [FormFieldType.TEXT]: TextInput,
  [FormFieldType.SELECT]: SelectInput,
  [FormFieldType.DATE]: DateInput,
  [FormFieldType.CHECKBOX]: CheckboxInput,
}
