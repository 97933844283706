import Title from "components/text/Title"
import TextInput from "components/inputs/TextInput"
import Button from "components/buttons/Button"
import useSendWellnessCode from "features/auth/useSendWellnessCode"
import { Dispatch, FC, SetStateAction } from "react"

interface Props {
  setUserId: Dispatch<SetStateAction<string | undefined>>
  setContact: Dispatch<SetStateAction<string | undefined>>
}

const SendWellnessCode: FC<Props> = ({ setUserId, setContact }) => {
  const { handleInputChange, handleSumbit, isButtonDisabled, error } =
    useSendWellnessCode({ setUserId, setContact })

  return (
    <>
      <Title size="m">Sign In to Your Account</Title>
      <div className="text-2xl mt-6">
        Enter your mobile or email to continue.
      </div>
      <div className="mt-1">
        If you don’t have an account, we’ll create one for you.
      </div>
      <div className="mt-6">
        <TextInput
          placeholder="Mobile or email"
          onChange={handleInputChange}
          error={error}
        />
      </div>
      <div className="mt-6">
        By clicking ‘Get Started’, you agree to DrWell’s <b>Terms of Service</b> and <b>Privacy Policy</b>.
      </div>
      <div className="mt-6">
        <Button disabled={isButtonDisabled} onClick={handleSumbit}>
          Get Started
        </Button>
      </div>
    </>
  )
}

export default SendWellnessCode
