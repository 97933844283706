import Table from "components/tables/Table"
import { ReactNode, useState } from "react"
import Button from "components/buttons/Button"
import ManageSubscriptionPopup from "features/account/subscriptions/ManageSubscriptionPopup"

const data = [
  {
    subscription: "Bank Your Botox (120 units per month)",
    frequency: "Every 30 days",
    next_payment: "July 19, 2024",
    price: "$150.00",
  },
  {
    subscription: "Bank Your Botox (120 units per month)",
    frequency: "Every 30 days",
    next_payment: "July 19, 2024",
    price: "$150.00",
  },
]

const ActiveSubscriptions = () => {
  const [isOpen, setOpen] = useState(false)
  const tableData: ReactNode[][] = []

  data.forEach((sub) => {
    tableData.push([
      sub.subscription,
      sub.frequency,
      sub.next_payment,
      sub.price,
      <div className="flex justify-end">
        <Button size="s" fullWidth={false} onClick={() => setOpen(true)}>
          Manage
        </Button>
      </div>,
    ])
  })

  const head = ["Name", "Frequency", "Next Payment", "Price", ""]

  return (
    <>
      <ManageSubscriptionPopup isOpen={isOpen} onClose={() => setOpen(false)} />
      <Table
        title="Active subscriptions"
        head={head}
        data={tableData}
        emptyText="You have no active subscriptions."
      />
    </>
  )
}

export default ActiveSubscriptions
