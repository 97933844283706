import { FC, ReactNode, HTMLAttributes } from "react"

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  color?: "grey" | "black"
  size?: "s" | "m" | "l" | "xl"
  className?: string
}

const colorClasses: Record<string, string> = {
  grey: "text-gray-charcoal",
  black: "text-black-primary",
}

const sizeClasses: Record<string, string> = {
  s: "text-sm",
  m: "text-base",
  l: "text-lg",
  xl: "text-xl",
}

const Text: FC<Props> = ({
  children,
  color = "black",
  size = "m",
  className,
}) => {
  const colorClass = colorClasses[color]
  const sizeClass = sizeClasses[size]

  return (
    <div
      className={`font-body font-medium ${colorClass} ${sizeClass} ${className}`}
    >
      {children}
    </div>
  )
}

export default Text
