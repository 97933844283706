import { ButtonHTMLAttributes, FC, ReactNode } from "react"
import { ReactComponent as EditSvg } from "assets/images/edit.svg"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  icon?: "edit"
}

const icons = {
  edit: EditSvg,
}

const Button: FC<Props> = ({ children, icon, ...buttonProps }) => {
  const IconComp = icon ? icons[icon] : null

  return (
    <button
      type="button"
      className={`bg-none border-0 font-semibold text-lg hover:underline`}
      {...buttonProps}
    >
      {IconComp && <IconComp className="inline-block mr-1 relative -top-1" />}
      {children}
    </button>
  )
}

export default Button
