import Title from "components/text/Title"
import TextInput from "components/inputs/TextInput"
import Button from "components/buttons/Button"
import useWellnessCodeForm from "features/wellnesscode/useWellnessCodeForm"
import { FC } from "react"

interface Props {
  contact: string | undefined
  isDisabled: boolean
  onSubmit: (value: string) => void
  onCancel: () => void
  error?: string
  submitButtonText?: string
}

const WellnessCodeForm: FC<Props> = ({
  contact,
  isDisabled = false,
  onSubmit,
  onCancel,
  error,
  submitButtonText = "Unlock my journey",
}) => {
  const { handleInputChange, handlePaste, handleSubmit, values } =
    useWellnessCodeForm({ onSubmit })
  const inputs = []
  const value = values.join("")

  for (let i = 0; i < 6; i++) {
    inputs.push(
      <TextInput
        key={i}
        onChange={(e) => handleInputChange(i, e.target.value)}
        value={values[i]}
        inputSize="l"
        className="text-center"
        maxLength={1}
        onPaste={handlePaste}
      />,
    )
  }

  return (
    <>
      <Title size="m">Your personal wellness key is on the way!</Title>
      <div className="mt-6">
        Enter the code sent to <b>{contact}</b> <br />
        to unlock your wellness journey.
      </div>
      <div className="mt-6 flex justify-center space-x-2">{inputs}</div>
      {error && <div className="text-red mt-1">{error}</div>}
      <div className="mt-6">
        <Button
          disabled={isDisabled || value.length < 6}
          onClick={handleSubmit}
        >
          {submitButtonText}
        </Button>
      </div>
      <div className="mt-3">
        <Button color="white" disabled={isDisabled} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  )
}

export default WellnessCodeForm
