import { api } from "app/services/api"
import { buildRest } from "app/services/restEndpoints"
import { Subscription } from "../../types/entities/subscription"

export const subscriptionApi = api.injectEndpoints({
  endpoints: (build) => {
    const rest = buildRest<Subscription>(build, "subscriptions")
    return {
      getSubscription: rest.one,
    }
  },
})

export const { useGetSubscriptionQuery } = subscriptionApi
