import { FC } from "react"

interface Props {
  name: string
  value?: string | null
}

const TextField: FC<Props> = ({ name, value }) => {
  return (
    <div className="mt-8">
      <div className="font-bold text-jacarta text-base">{name}</div>
      <div className="mt-2 text-jacarta">{value ?? "-"}</div>
    </div>
  )
}

export default TextField
