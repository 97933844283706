import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "app/store"
import { User } from "types/entities/userTypes"

type AuthState = {
  user: User | null
  signInPopup: boolean
}

const initialState: AuthState = {
  user: null,
  signInPopup: false,
}

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, { payload: user }: PayloadAction<User | null>) => {
      state.user = user
    },
    showSignInPopup: (state) => {
      state.signInPopup = true
    },
    hideSignInPopup: (state) => {
      state.signInPopup = false
    },
  },
})

export const { setUser, showSignInPopup, hideSignInPopup } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user

export const selectSignInPopup = (state: RootState) => state.auth.signInPopup
