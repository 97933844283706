import { FC, ReactNode } from "react"
import Title from "components/text/Title"

interface Props {
  title?: string
  head: string[]
  data: ReactNode[][]
  emptyText: string
}

const Table: FC<Props> = ({ title, head, data, emptyText }) => {
  return (
    <div className="mb-8 text-lg">
      {title && (
        <Title size="l" className="mb-10">
          {title}
        </Title>
      )}
      <table className="table-auto w-full">
        <thead>
          <tr className="">
            {head.map((name, index) => (
              <th key={index} className="py-8 text-left border-b border-black">
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i1) => (
            <tr key={i1}>
              {row.map((cell, i2) => (
                <td key={i2} className="py-8 border-b border-silver-light">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {data.length === 0 && <div className="py-8 text-center">{emptyText}</div>}
    </div>
  )
}

export default Table
