import { ReactComponent as LogoSvg } from "assets/images/logo.svg"

const PoweredBy = () => (
  <>
    <div className="flex justify-center items-center mt-6">
      <div className="h-px bg-silver flex-grow"></div>
      <div className="px-3 text-sm text-auroMetalSaurus">Powered by</div>
      <div className="h-px bg-silver flex-grow"></div>
    </div>
    <div className="mt-2 flex justify-center">
      <LogoSvg />
    </div>
    <div className="text-xl mt-4">Your digital prescription for a</div>
    <div className="text-3xl mt-1 text-bronze font-bold">
      <span className="bg-gradient-to-r from-bronze to-silver-pale inline-block text-transparent bg-clip-text">
        healthier life.
      </span>
    </div>
  </>
)

export default PoweredBy
