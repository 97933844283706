import { FC, SelectHTMLAttributes } from "react"
import { BaseFieldProps } from "types/core/formTypes"
import InputLabel from "components/inputs/InputLabel"
import InputError from "components/inputs/InputError"

export interface SelectOption {
  value: string
  label: string
}

interface Props
  extends BaseFieldProps,
    SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[]
}

const SelectInput: FC<Props> = ({
  label,
  error,
  options = [],
  ...inputProps
}) => {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <select
        className="block w-full p-4 text-base leading-4 rounded-md border border-silver focus:outline-none
          focus:border-jacarta placeholder-silver-quick bg-white"
        {...inputProps}
      >
        <option value=""></option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <InputError>{error}</InputError>
    </div>
  )
}

export default SelectInput
