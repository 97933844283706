import BaseLayout from "layouts/BaseLayout"
import InactiveSubscriptions from "features/account/subscriptions/InactiveSubscriptions"
import ActiveSubscriptions from "features/account/subscriptions/ActiveSubscriptions"

const AccountSubscriptions = () => {
  return (
    <BaseLayout title="Subscriptions">
      <ActiveSubscriptions />
      <div className="h-24"></div>
      <InactiveSubscriptions />
    </BaseLayout>
  )
}

export default AccountSubscriptions
