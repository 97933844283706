import { User } from "types/entities/userTypes"
import { buildRest } from "app/services/restEndpoints"
import { api } from "app/services/api"

export const usersApi = api.injectEndpoints({
  endpoints: (build) => {
    const rest = buildRest<User>(build, "users")
    return {
      getUser: rest.one,
      getUsers: rest.list,
      updateUser: rest.update,
      createUser: rest.create,
      deleteUser: rest.delete,
    }
  },
})

export const {} = usersApi
