import React, { FC, ImgHTMLAttributes } from "react"

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  alt: string
}

const Image: FC<Props> = ({ src, alt, ...imgProps }) => {
  return <img src={src} alt={alt} className="max-w-sm h-auto" {...imgProps} />
}

export default Image
