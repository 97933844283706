import { ButtonHTMLAttributes, FC, ReactNode } from "react"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  size?: "s" | "m" | "l" | "xl"
  className?: string
}

const sizeClasses: Record<string, string> = {
  s: "text-3xl",
  m: "text-4xl",
  l: "text-5xl",
  xl: "text-6xl text-center mb-12",
}

const Title: FC<Props> = ({ children, size = "l", className }) => {
  const sizeClass = sizeClasses[size]

  return (
    <div className={`font-header font-medium ${sizeClass} ${className}`}>
      {children}
    </div>
  )
}

export default Title
