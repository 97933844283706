import Popup from "components/popups/Popup"
import { FC, useState } from "react"
import Tabs from "components/tabs/Tabs"
import ManageSubscriptionDetails from "features/account/subscriptions/ManageSubscriptionDetails"
import ManageSubscriptionHistory from "features/account/subscriptions/ManageSubscriptionHistory"

interface Props {
  isOpen: boolean
  onClose: () => void
}

const ManageSubscriptionPopup: FC<Props> = ({ isOpen = false, onClose }) => {
  const [active, setActive] = useState("Details")
  const handleTabChange = (item: string) => setActive(item)

  return (
    <Popup isOpen={isOpen} onClose={onClose} verticalCenter={false}>
      <div className="w-[680px] -mt-10 text-left">
        <div className="text-3xl font-semibold mb-1">Manage subscription</div>
        <div className="text-gray-slate">
          Bank Your Botox (120 units per month)
        </div>
        <Tabs
          items={["Details", "History"]}
          onChange={handleTabChange}
          className="mt-8 mb-10"
        />
        {active === "Details" ? (
          <ManageSubscriptionDetails />
        ) : (
          <ManageSubscriptionHistory />
        )}
      </div>
    </Popup>
  )
}

export default ManageSubscriptionPopup
