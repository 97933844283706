import useInputWellnessCode from "features/auth/useInputWellnessCode"
import { FC } from "react"
import { User } from "types/entities/userTypes"
import WellnessCodeForm from "features/wellnesscode/WellnessCodeForm"

interface Props {
  userId: string
  contact: string | undefined
  onSuccess: (token: string, user: User) => void
  cancel: () => void
}

const InputWellnessCode: FC<Props> = ({
  userId,
  contact,
  onSuccess,
  cancel,
}) => {
  const { handleSumbit, isLoading, error } = useInputWellnessCode({
    userId,
    onSuccess,
  })

  return (
    <WellnessCodeForm
      contact={contact}
      isDisabled={isLoading}
      onSubmit={handleSumbit}
      onCancel={cancel}
      error={error}
    />
  )
}

export default InputWellnessCode
