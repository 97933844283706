import { User } from "types/entities/userTypes"
import { setUser } from "features/auth/authSlice"
import { Dispatch } from "react"

const TOKEN_NAME = "user_token"
const USER_NAME = "user"

export const setCredentials = (accessToken: string, user: User) => {
  localStorage.setItem(TOKEN_NAME, accessToken)
  localStorage.setItem(USER_NAME, JSON.stringify(user))
}

export const clearCredentials = () => {
  localStorage.removeItem(TOKEN_NAME)
  localStorage.removeItem(USER_NAME)
}

export const getAccessToken = () => localStorage.getItem(TOKEN_NAME)

export const getMe = () => localStorage.getItem(USER_NAME)

export const logoutFn = (dispatch: Dispatch<any>) => {
  clearCredentials()
  dispatch(setUser(null))
}
