import { FC } from "react"
import Box from "components/blocks/Box"
import { ReactComponent as AlertSvg } from "assets/images/alert-circle.svg"
import Button from "components/buttons/Button"
import TextField from "components/fields/TextField"

interface Props {}

const ManageSubscriptionDetails: FC<Props> = ({}) => {
  return (
    <>
      <Box className="text-center">
        <AlertSvg className="mb-8 inline-block" />
        <div className="mb-6">Your subscription payment was unsuccessful.</div>
        <div className="mb-10">
          Please update your payment details to reactivate your subscription.
        </div>
        <div className="flex justify-center">
          <Button fullWidth={false}>Update payment details</Button>
        </div>
      </Box>
      <div className="mt-10 mb-2 text-lg font-semibold">
        Bank Your Botox (120 units per month)
      </div>
      <div>
        Bae Club Injectables & SkinCare Membership. Get exclusive discounts for
        a low monthly fee. By purchasing this Membership, you agree to pay a fee
        per Month (rate available after logging in). See full terms and
        conditions.
      </div>
      <div className="font-bold mt-8 mb-1">Additional information</div>
      <div>
        That's right! For a low monthly membership fee you will receive up to a
        20% discount when you purchase these injectables (Botox, XEOMIN,
        fillers), the Obagi skin care line and Latisse! Tax and shipping is
        included in the discounted purchase price. Skin care products will be
        sent to you, injectables must be redeemed in the office.
        <br />
        If you're renewing your Membership after previously cancelling, your
        membership, and associated discounts, will start after your next visit.
      </div>
      <div className="grid grid-cols-2 gap-x-4 mt-4">
        <div>
          <TextField name="Enrollment date" value="July 30, 2024" />
          <TextField name="Next payment date" value="August 29, 2024" />
          <TextField
            name="Pacific Heights Plastic Surgery"
            value="2100 Webster Street, #429 San Francisco, California 94115 \(415) 923-3005"
          />
        </div>
        <div>
          <TextField
            name="Billing frequency"
            value="$150.00 every 30 days for 6 billing cycles"
          />
          <TextField
            name="Auto-pay"
            value="Enrolled in auto-pay (disable auto-pay)x"
          />
        </div>
      </div>
    </>
  )
}

export default ManageSubscriptionDetails
