import { FC } from "react"
import Table from "components/tables/Table"

interface Props {}

const ManageSubscriptionHistory: FC<Props> = ({}) => {
  const head = ["Payment Date", "Amount", "Status"]

  return (
    <>
      <Table
        head={head}
        data={[["August 29, 2024", "$150", "Failed"]]}
        emptyText="Empty history"
      />
    </>
  )
}

export default ManageSubscriptionHistory
