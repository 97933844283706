import { accountMenuItems } from "features/account/menu/menuItems"
import { Link } from "react-router-dom"

const AccountMenu = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
      {accountMenuItems.map((item, index) => (
        <Link key={index} to={item.link}>
          <div className="flex space-x-4 border p-6 rounded-md hover:bg-gray-100 transition cursor-pointer">
            <div>
              <item.icon />
            </div>
            <div className="pt-1">
              <p className="mb-2 text-xl font-medium">{item.title}</p>
              <p className="text-gray">{item.description}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default AccountMenu
